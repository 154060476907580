import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import Analysis from '@/views/Overview/Analysis/Index.vue'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Root',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'home',
        name: 'HmoeIndex',
        component: Analysis,
        meta: { title: t('overview.home') }
      }
    ]
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirects',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true,
      alwaysShow: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/overview',
    component: Layout,
    redirect: '/overview/batteryOperate',
    name: 'overview',
    meta: {
      title: t('overview'),
      icon: 'ep:data-analysis',
      alwaysShow: true
    },
    children: [
      // {
      //   path: 'analysis',
      //   name: 'analysis',
      //   component: Analysis,
      //   meta: {
      //     title: t('overview.home'),
      //     hidden: true
      //   }
      // },
      {
        path: 'batteryOperate',
        name: 'Index',
        component: () => import('@/views/Overview/BatteryOperate/Index.vue'),
        meta: {
          title: t('overview.batteryOperate')
        },
        children: [
          {
            path: 'detail',
            name: 'detail',
            component: () => import('@/views/Overview/BatteryOperate/Detail.vue'),
            meta: {
              hidden: true,
              title: t('overview.batteryDetails'),
              noTagsView: true
            }
          },
          {
            path: 'faultDetail',
            name: 'faultDetail',
            component: () => import('@/views/Overview/BatteryOperate/FaultDetail.vue'),
            meta: {
              title: t('overview.detailsFailure'),
              noTagsView: true,
              hidden: true
            }
          },
          {
            path: 'record',
            name: 'record1',
            component: () => import('@/views/Overview/BatteryOperate/Record.vue'),
            meta: {
              title: t('common.operationRecords'),
              noTagsView: true,
              hidden: true
            }
          },
          {
            path: 'batteryTrack',
            name: 'batteryTrack',
            component: () => import('@/views/Overview/BatteryOperate/BatteryTrack.vue'),
            meta: {
              title: t('common.batteryTrack'),
              noTagsView: true,
              hidden: true
            }
          },
          {
            path: 'batteryGPS',
            name: 'batteryGPS',
            component: () => import('@/views/Overview/BatteryOperate/BatteryGPS.vue'),
            meta: {
              title: t('common.batteryGPSHistoryRecord'),
              noTagsView: true,
              hidden: true
            }
          }
        ]
      },
      {
        path: 'cabinetOperate',
        name: 'cabinetOperate',
        component: () => import('@/views/Overview/CabinetOperate/Index.vue'),
        meta: {
          title: t('overview.cabinetOperate')
        },
        children: [
          {
            path: 'detail',
            name: 'detail1',
            component: () => import('@/views/Overview/CabinetOperate/Detail.vue'),
            meta: {
              title: t('overview.cabinetMachineDetails'),
              hidden: true
            },
            children: [
              {
                path: 'more',
                name: 'more',
                component: () => import('@/views/Overview/CabinetOperate/More.vue'),
                meta: {
                  title: t('common.more'),
                  hidden: true
                }
              }
            ]
          },
          {
            path: 'otaUp',
            name: 'otaUp',
            component: () => import('@/views/Overview/CabinetOperate/OtaUp.vue'),
            meta: {
              title: t('overview.OTAUpgrade'),
              hidden: true
            }
          },
          {
            path: 'voltage',
            name: 'voltage',
            component: () => import('@/views/Overview/CabinetOperate/Voltage.vue'),
            meta: {
              title: t('overview.voltage'),
              hidden: true
            }
          },
          {
            path: 'useRecord',
            name: 'useRecord',
            component: () => import('@/views/Overview/CabinetOperate/UseRecord.vue'),
            meta: {
              title: t('overview.electricalCabinetUsageRecords'),
              hidden: true
            }
          },
          {
            path: 'electric',
            name: 'electric',
            component: () => import('@/views/Overview/CabinetOperate/Electric.vue'),
            meta: {
              title: t('overview.electricityBill'),
              hidden: true
            }
          },
          {
            path: 'snapshot',
            name: 'snapshot',
            component: () => import('@/views/Overview/CabinetOperate/Snapshot.vue'),
            meta: {
              hidden: true,
              title: t('overview.cabinetSnapshot')
            }
          },
          {
            path: 'record',
            name: 'record3',
            component: () => import('@/views/Overview/CabinetOperate/Record.vue'),
            meta: {
              hidden: true,
              title: t('overview.record')
            }
          },
          {
            path: 'upDownRecord',
            name: 'record4',
            component: () => import('@/views/Overview/CabinetOperate/upDownRecord.vue'),
            meta: {
              hidden: true,
              title: t('common.loginLogoutRecords')
            }
          },
          {
            path: 'warningRecord',
            name: 'record5',
            component: () => import('@/views/Overview/CabinetOperate/warningRecord.vue'),
            meta: {
              hidden: true,
              title: t('common.alertRecords')
            }
          },
          {
            path: 'changeBatterySet',
            name: 'changeBatterySet',
            component: () => import('@/views/Overview/CabinetOperate/ChangeBatterySet.vue'),
            meta: {
              hidden: true,
              title: t('electricCabinetList.batterySwappingSettings')
            }
          }
        ]
      },
      {
        path: 'car',
        name: 'car',
        component: () => import('@/views/Overview/Car/Index.vue'),
        meta: {
          title: t('overview.vehicleOperations')
        },
        children: [
          {
            path: 'detail',
            name: 'detail2',
            component: () => import('@/views/Overview/Car/Detail.vue'),
            meta: {
              title: t('overview.vehicleDetails'),
              hidden: true
            }
          }
        ]
      },
      // {
      //   path: 'batteryUser',
      //   name: 'batteryUser',
      //   component: () => import('@/views/Overview/BatteryUser/Index.vue'),
      //   meta: {
      //     title: '换电用户运营'
      //   },
      //   children: [
      //     {
      //       path: 'detail',
      //       name: 'detail3',
      //       component: () => import('@/views/Overview/BatteryUser/Detail.vue'),
      //       meta: {
      //         title: '换电用户运营详情',
      //         hidden: true
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: 'carUser',
      //   name: 'carUser',
      //   component: () => import('@/views/Overview/CarUser/Index.vue'),
      //   meta: {
      //     title: '租车用户运营'
      //   },
      //   children: [
      //     {
      //       path: 'detail',
      //       name: 'detail4',
      //       component: () => import('@/views/Overview/CarUser/Detail.vue'),
      //       meta: {
      //         title: '租车用户运营详情',
      //         hidden: true
      //       }
      //     }
      //   ]
      // },
      {
        path: 'cabinetSoc',
        name: 'cabinetSoc',
        component: () => import('@/views/Overview/CabinetOperate/CabinetSoc.vue'),
        meta: {
          hidden: true,
          title: t('overview.electricityBill')
        }
      }
    ]
  },
  {
    path: '/partner',
    name: 'Partners',
    component: Layout,
    meta: {
      title: t('partners'),
      icon: 'ant-design:usergroup-add-outlined',
      alwaysShow: true
    },
    children: [
      {
        path: 'franchisee',
        name: 'franchisee',
        component: () => import('@/views/Partner/Franchisee.vue'),
        meta: {
          title: t('partners.franchiseeList')
        }
      },
      {
        path: 'store',
        name: 'store',
        component: () => import('@/views/Partner/Store.vue'),
        meta: {
          title: t('partners.storeList')
        }
      }
    ]
  },
  {
    path: '/property',
    name: 'Property',
    component: Layout,
    meta: {
      title: t('property'),
      icon: 'ep:coin',
      alwaysShow: true
    },
    children: [
      {
        path: 'electricCabinetList',
        name: 'electricCabinetList',
        component: () => import('@/views/Property/ElectricCabinetList.vue'),
        meta: {
          title: t('electricCabinetList')
        }
      },
      {
        path: 'cabinetModel',
        name: 'cabinetModel',
        component: () => import('@/views/Property/CabinetModel.vue'),
        meta: {
          title: t('cabinetModel')
        }
      },
      {
        path: 'batteryList',
        name: 'batteryList',
        component: () => import('@/views/Property/BatteryList.vue'),
        meta: {
          title: t('batteryList')
        }
      },
      {
        path: 'batteryModel',
        name: 'BatteryModel',
        component: () => import('@/views/Property/BatteryModel.vue'),
        meta: {
          title: t('batteryModel')
        }
      },
      {
        path: 'vehicleList',
        name: 'vehicleList',
        component: () => import('@/views/Property/VehicleList.vue'),
        meta: {
          title: t('vehicleList')
        }
      },
      {
        path: 'vehicleModel',
        name: 'vehicleModel',
        component: () => import('@/views/Property/VehicleModel.vue'),
        meta: {
          title: t('vehicleModel')
        }
      },
      {
        path: 'batchSetting',
        component: () => import('@/views/Property/BatchSetting.vue'),
        name: 'batchSetting',
        meta: {
          hidden: true,
          title: t('electricCabinetList.batchSetting'),
          noTagsView: true
        }
      },
      {
        path: 'createTemplate',
        component: () => import('@/views/Property/CreateTemplate.vue'),
        name: 'createTemplate',
        meta: {
          hidden: true,
          title: t('electricCabinetList.createATemplate'),
          noTagsView: true
        }
      },
      {
        path: 'templateSettings',
        component: () => import('@/views/Property/TemplateSettings.vue'),
        name: 'templateSettings',
        meta: {
          hidden: true,
          title: t('electricCabinetList.atemplateSet'),
          noTagsView: true
        }
      },
      {
        path: 'batchUpgrade',
        component: () => import('@/views/Property/BatchUpgrade.vue'),
        name: 'batchUpgrade',
        meta: {
          hidden: true,
          title: t('electricCabinetList.batchUpgrade'),
          noTagsView: true
        }
      },
      {
        path: 'batchSetting',
        component: () => import('@/views/Property/BatchSetting.vue'),
        name: 'batchSetting',
        meta: {
          hidden: true,
          title: t('electricCabinetList.batchSetting'),
          noTagsView: true
        }
      }
    ]
  },
  {
    path: '/batterybusiness',
    name: 'batterybusiness',
    component: Layout,
    meta: {
      title: t('batterySwappingOperation'),
      alwaysShow: true,
      icon: 'ep:pie-chart'
    },
    children: [
      {
        path: 'packageConfig',
        name: 'packageConfig',
        component: () => import('@/views/BatteryBusiness/PackageConfig.vue'),
        meta: {
          title: t('packageConfig')
        }
      },
      {
        path: 'packageBuyRecord',
        name: 'packageBuyRecord',
        component: () => import('@/views/BatteryBusiness/PackageBuyRecord.vue'),
        meta: {
          title: t('buyRecord')
        }
      },
      {
        path: 'insuranceConfig',
        name: 'insuranceConfig',
        component: () => import('@/views/BatteryBusiness/InsuranceConfig.vue'),
        meta: {
          title: t('insurance')
        }
      },
      {
        path: 'insuranceBuyRecord',
        name: 'insuranceBuyRecord',
        component: () => import('@/views/BatteryBusiness/InsuranceBuyRecord.vue'),
        meta: {
          title: t('insuranceRecord')
        }
      },
      {
        path: 'record',
        name: 'record2',
        component: () => import('@/views/BatteryBusiness/Record.vue'),
        meta: {
          title: t('batteryReplacementRecord')
        }
      },
      {
        path: 'batteryRecord',
        name: 'batteryRecord',
        component: () => import('@/views/BatteryBusiness/BatteryRecord.vue'),
        meta: {
          title: t('rental/RefundRecord')
        }
      },
      {
        path: 'orderRefund',
        name: 'orderRefund',
        component: () => import('@/views/BatteryBusiness/OrderRefund.vue'),
        meta: {
          title: t('depositRefundReview')
        }
      },
      {
        path: 'orderRecord',
        name: 'orderRecord',
        component: () => import('@/views/BatteryBusiness/OrderRecord.vue'),
        meta: {
          title: t('depositPaymentRecord')
        }
      },
      {
        path: 'operationRecords',
        name: 'operationRecords',
        component: () => import('@/views/BatteryBusiness/OperationRecords.vue'),
        meta: {
          title: t('common.operationRecords'),
          noTagsView: true,
          hidden: true
        }
      }
    ]
  },
  {
    path: '/carbusiness',
    name: 'carbusiness',
    component: Layout,
    meta: {
      title: t('carOperation'),
      icon: 'ep:odometer',
      alwaysShow: true
    },
    children: [
      {
        path: 'carNo',
        name: 'carNo',
        component: () => import('@/views/CarBusiness/CarNo.vue'),
        meta: {
          title: t('vehicleLaunch')
        }
      },
      {
        path: 'packageConfig',
        name: 'packageConfig2',
        component: () => import('@/views/CarBusiness/PackageConfig.vue'),
        meta: {
          title: t('packageConfig')
        }
      },
      {
        path: 'packageBuyRecord',
        name: 'packageBuyRecord2',
        component: () => import('@/views/CarBusiness/PackageBuyRecord.vue'),
        meta: {
          title: t('buyRecord')
        }
      },
      {
        path: 'insuranceConfig',
        name: 'insuranceConfig2',
        component: () => import('@/views/CarBusiness/InsuranceConfig.vue'),
        meta: {
          title: t('insurance')
        }
      },
      {
        path: 'insuranceBuyRecord',
        name: 'insuranceBuyRecord2',
        component: () => import('@/views/CarBusiness/InsuranceBuyRecord.vue'),
        meta: {
          title: t('insuranceRecord')
        }
      },
      {
        path: 'rentRecord',
        name: 'rentRecord',
        component: () => import('@/views/CarBusiness/RentRecord.vue'),
        meta: {
          title: t('carRentalRecords')
        }
      },
      {
        path: 'examine',
        name: 'examine',
        component: () => import('@/views/CarBusiness/Examine.vue'),
        meta: {
          title: t('returnReview')
        }
      },
      {
        path: 'orderRecord',
        name: 'orderRecord2',
        component: () => import('@/views/CarBusiness/OrderRecord.vue'),
        meta: {
          title: t('depositPaymentRecord')
        }
      },
      {
        path: 'orderRefund',
        name: 'orderRefund2',
        component: () => import('@/views/CarBusiness/OrderRefund.vue'),
        meta: {
          title: t('depositRefundReview')
        }
      },

      {
        path: 'bayCarMarage',
        name: 'baycarmarage',
        component: () => import('@/views/CarBusiness/BayCarMarage.vue'),
        meta: {
          title: t('buyingCarManage')
        }
      },
      {
        path: 'orderMange',
        name: 'orderMange',
        component: getParentLayout(),
        meta: {
          title: t('appointmentOrder'),
          alwaysShow: true
        },
        children: [
          {
            path: 'toVerify',
            name: 'toVerify',
            component: () => import('@/views/CarBusiness/OrderMange/ToVerify.vue'),
            meta: {
              title: t('toBeOrdersWriteOff')
            }
          },
          {
            path: 'hasVerify',
            name: 'hasVerify',
            component: () => import('@/views/CarBusiness/OrderMange/HasVerify.vue'),
            meta: {
              title: t('cancelledOrders')
            }
          },
          {
            path: 'refund',
            name: 'refund',
            component: () => import('@/views/CarBusiness/OrderMange/Refund.vue'),
            meta: {
              title: t('refundOrder')
            }
          }
        ]
      }
    ]
  },
  {
    path: '/userMange',
    name: 'userMange',
    component: Layout,
    meta: {
      title: t('userManage'),
      icon: 'ep:user',
      alwaysShow: true
    },
    children: [
      {
        path: 'realNameUser',
        name: 'RealNameUser',
        component: () => import('@/views/UserMange/RealNameUser.vue'),
        meta: {
          title: t('memberManagement')
        }
      },
      {
        path: 'realUserDetail',
        name: 'realUserDetail',
        component: () => import('@/views/UserMange/RealUserDetail.vue'),
        meta: {
          hidden: true,
          title: t('memberManagement.memberDetails'),
          noTagsView: true
        }
      },
      {
        path: 'realUserRecord',
        name: 'realUserRecord',
        component: () => import('@/views/UserMange/RealUserRecord.vue'),
        meta: {
          hidden: true,
          title: t('memberOperationRecords'),
          noTagsView: true
        }
      },
      {
        path: 'loginUser',
        name: 'loginUser',
        component: () => import('@/views/UserMange/LoginUser.vue'),
        meta: {
          title: t('logOnUser')
        }
      },
      {
        path: 'loginRecord',
        name: 'loginRecord',
        component: () => import('@/views/UserMange/LoginRecord.vue'),
        meta: {
          title: t('common.loginRecords'),
          hidden: true
        }
      },
      {
        path: 'verifyUser',
        name: 'verifyUser',
        component: () => import('@/views/UserMange/VerifyUser.vue'),
        meta: {
          title: t('realNameVerification')
        }
      }
    ]
  },
  {
    path: '/aftersales',
    name: 'aftersales',
    component: Layout,
    meta: {
      title: t('afterSaleService'),
      icon: 'ep:phone',
      alwaysShow: true
    },
    children: [
      {
        path: 'message',
        name: 'aftersalesMessage',
        component: () => import('@/views/Aftersales/Message.vue'),
        meta: {
          title: t('leaveMessage')
        }
      },
      {
        path: 'Fault',
        name: 'aftersalesFault',
        component: () => import('@/views/Aftersales/Fault.vue'),
        meta: {
          title: t('faultReporting')
        }
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('@/views/Aftersales/Help.vue'),
        meta: {
          title: t('help')
        }
      }
    ]
  },
  {
    path: '/set',
    name: 'set',
    component: Layout,
    meta: {
      title: t('sysSet'),
      alwaysShow: true,
      icon: 'ep:setting'
    },
    children: [
      {
        path: 'sys',
        name: 'sys',
        component: () => import('@/views/Set/Sys.vue'),
        meta: {
          title: t('sysSet')
        }
      },
      {
        path: 'role',
        name: 'sysrole',
        component: () => import('@/views/Set/Role.vue'),
        meta: {
          title: t('roleConfiguration')
        }
      },
      {
        path: 'proUser',
        name: 'proUser',
        component: () => import('@/views/Set/ProUser.vue'),
        meta: {
          title: t('managers')
        }
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: () => import('@/views/Set/Agreement.vue'),
        meta: {
          title: t('protocolSet')
        }
      },
      {
        path: 'message',
        name: 'sysmessage',
        component: () => import('@/views/Set/Message.vue'),
        meta: {
          title: t('SMSSendingRecord')
        }
      },
      {
        path: 'mailbox',
        name: 'mailbox',
        component: () => import('@/views/Set/Mailbox.vue'),
        meta: {
          title: t('10231')
        }
      },
      {
        path: 'operatingRecord',
        name: 'operatingRecord',
        component: () => import('@/views/Set/OperatingRecord.vue'),
        meta: {
          title: t('common.operationRecords')
        }
      },
      {
        path: 'upNotification',
        name: 'upNotification',
        component: () => import('@/views/Set/UpNotification.vue'),
        meta: {
          title: t('versionUpdateLog')
        }
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('@/views/Set/Payment.vue'),
        meta: {
          title: t('paymentConfiguration')
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: Layout,
    meta: {
      title: t('xiliulou'),
      alwaysShow: true,
      icon: 'ant-design:usergroup-add-outlined'
    },
    children: [
      {
        path: 'permission',
        name: 'permission',
        component: () => import('@/views/Set/Permission.vue'),
        meta: {
          title: t('rightsManagement')
        }
      },
      {
        path: 'tenantUser',
        name: 'tenantUser',
        component: () => import('@/views/user/tenantUser.vue'),
        meta: {
          title: t('tenantList')
        }
      },
      {
        path: 'smsFrequency',
        name: 'smsFrequency',
        component: () => import('@/views/user/SmsFrequency.vue'),
        meta: {
          hidden: true,
          title: t('rechargeRecords'),
          noTagsView: true
        }
      },
      {
        path: 'permissionTemplate',
        name: 'permissionTemplate',
        component: () => import('@/views/user/PermissionTemplate.vue'),
        meta: {
          title: t('common.permissionTemplate')
        }
      },
      {
        path: 'batteryMaterial',
        name: 'batteryMaterial',
        component: () => import('@/views/user/BatteryMaterial.vue'),
        meta: {
          title: t('batteryMaterial')
        }
      },
      {
        path: 'OTAFileManagement',
        name: 'OTAFileManagement',
        component: () => import('@/views/user/OTAFileManagement.vue'),
        meta: {
          title: t('OTAFileManagement')
        }
      },
      {
        path: 'deviceCode',
        name: 'deviceCode',
        component: () => import('@/views/user/DeviceCode.vue'),
        meta: {
          title: t('common.deviceNumber')
        }
      }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
